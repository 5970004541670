import React from "react"
import { Box, Heading, Paragraph } from "grommet"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Roadmap from "../components/roadmap/Roadmap"

const RoadmapPage = () => (
  <Layout>
    <Seo title="Roadmap" />
    <Roadmap/>
  </Layout>
)

export default RoadmapPage
