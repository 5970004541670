import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Grid,
  Header,
  Heading, Keyboard,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet"
import {
  Blank,
  Next,
  Previous,
} from 'grommet-icons';
import { addMonths, capitalizeFirstLetter, sameMonth, subtractMonths } from "./utils"
import roadmap from './data.json';
import Swipe from './Swipe';
import LinkIcon from './LinkIcon';

const monthCounts = {
  small: 1,
  medium: 3,
  large: 4,
};

// allow for gap in Grid
const columnPercents = {
  small: 'full',
  medium: '32%',
  large: '24%',
};

const firstDate = (dateFields) =>
  dateFields.map(({ date }) => new Date(date)).sort((d1, d2) => d1 - d2)[0];

const byDate = (i1, i2) => {
  const d1 = firstDate(i1.dateFields);
  const d2 = firstDate(i2.dateFields);
  if (!d1) return -1;
  if (!d2) return 1;
  return d1 - d2;
};

const now = new Date();
now.setDate(1);

const Roadmap = () => {
  const responsive = useContext(ResponsiveContext);
  const [date, setDate] = useState(now);

  const items = useMemo(
    () =>
      roadmap
        ? roadmap.items.map((item, index) => ({ ...item, index }))
        : undefined,
    [roadmap],
  );

  // months to show, array of Date objects
  const months = useMemo(
    () =>
      Array.from(Array(monthCounts[responsive])).map((_, i) =>
        addMonths(date, i),
      ),
    [date, responsive],
  );

  // normalize data for how we want to display it
  // section -> month -> items
  const sections = useMemo(() => {
    let result = [];
    if (roadmap) {
      const monthsItems = items.filter(({ dateFields }) =>
        months.some((month) =>
          dateFields.some((dateField) => sameMonth(month, dateField.date)),
        ),
      );
      result = roadmap.sections
        .map((name) => ({
          name,
          months: months.map((month) => ({
            month,
            items: monthsItems
              .filter(({ section, dateFields }) =>
                dateFields.some(
                  (dateField) =>
                    name === section && sameMonth(month, dateField.date),
                ),
              )
              .sort(byDate),
          })),
        }))
        .filter((s) => s.months.some((m) => m.items.length) && s.name !== '');
      // add any non-section items
      const nonSectionItems = monthsItems.filter(({ section }) => !section);
      if (nonSectionItems.length) {
        result.push({
          months: months.map((month) => ({
            month,
            items: nonSectionItems
              .filter(({ dateFields }) =>
                dateFields.some((dateField) =>
                  sameMonth(month, dateField.date),
                ),
              )
              .sort(byDate),
          })),
        });
      }
    }
    return result;
  }, [items, months, roadmap]);

  const Row = (props) => {
    if (responsive === 'small') return <Box {...props} />;
    return (
      <Grid
        columns={[
          'flex',
          ['small', responsive === 'medium' ? 'xlarge' : '80vw'],
          'flex',
        ]}
      >
        <Box />
        <Grid columns={columnPercents[responsive]} gap="small" {...props} />
        <Box />
      </Grid>
    );
  };

  const onNext = useCallback(() => setDate(addMonths(date, 1)), [date]);
  const onPrevious = useCallback(() => setDate(subtractMonths(date, 1)), [
    date,
  ]);

  const Month = ({ month, items }) => {
    return (
      <Box
        gap="medium"
        pad={{ vertical: 'medium', horizontal: 'small' }}
        background="background-contrast"
        responsive={false}
      >
        {items.map((item) => (
          <Item key={item.name} month={month} {...item} />
        ))}
      </Box>
    );
  }; // Month

  const Item = ({ dateFields, index, linkFields, month, name, note }) => {
    const labels = [];
    for (var x in dateFields) {
      const stage = dateFields[x].stage;
      labels.push(roadmap.labels.find(({ name }) => name === stage));
    }

    return (
      <Card background="background-front" elevation="small">
        <CardBody justify="between" direction="row" gap="medium" pad="medium">
          <Box flex>
            <Heading margin="none" size="small" level={4}>
              {name}
            </Heading>
            {note && (
              <Paragraph size="small" margin={{ bottom: 'none' }}>
                {note}
              </Paragraph>
            )}
          </Box>
          <Box flex={false} gap="small">
            {linkFields
              .filter(({ linkUrl }) => linkUrl)
              .map(({ linkUrl }) => (
                <Box key={linkUrl} align="center">
                  <Button
                    plain
                    icon={<LinkIcon url={linkUrl} />}
                    href={linkUrl}
                    target='_blank'
                  />
                </Box>
              ))}
          </Box>
        </CardBody>
        {dateFields
          .filter((dateField) => sameMonth(month, dateField.date))
          .map((dateField, index) => (
            <CardFooter
              key={dateField.stage}
              pad={{
                vertical: 'small',
                horizontal: 'medium',
              }}
              background={(labels[index] && labels[index].color) || ''}
            >
              <Text size="small" weight="bold" key={`${index}stage`}>
                {dateField.stage}
              </Text>
              <Text size="small" weight="bold" key={`${index}progress`}>
                {dateField.progress}
              </Text>
            </CardFooter>
          ))}
      </Card>
    );
  }; // Item

  const Name = () =>  (
      <Heading textAlign="center" size="small" margin="none">
        {roadmap.name}
      </Heading>
  );

  return (
    <Box>
      <Header pad="small" justify='center'>
          <Name />
      </Header>
      <Keyboard
        target="document"
        onRight={onNext}
        onLeft={onPrevious}
      >
      <Swipe fill onSwipeLeft={onNext} onSwipeRight={onPrevious} gap="small">
        <Box flex={false}>
          <Row>
            {months.map((month, index) => (
              <Box key={month} direction="row" align="end" justify="between">
                {index === 0 ? (
                  <Button
                    icon={<Previous />}
                    hoverIndicator
                    onClick={onPrevious}
                  />
                ) : (
                  <Blank />
                )}
                <Box align="center">
                  <Text color="text-weak">
                    {month.toLocaleString(undefined, { year: 'numeric' })}
                  </Text>
                  <Heading level={2} size="small" margin="none">
                    {capitalizeFirstLetter(month.toLocaleString('bg-BG', { month: 'long' }))}
                  </Heading>
                </Box>
                {index === months.length - 1 ? (
                  <Button icon={<Next />} hoverIndicator onClick={onNext} />
                ) : (
                  <Blank />
                )}
              </Box>
            ))}
          </Row>
        </Box>
        <Box flex overflow="auto" pad={{ bottom: 'medium' }}>
          {Object.values(sections).map(({ name, months }) => (
            <Box flex={false} key={name || 'none'}>
              <Row>
                <Heading level={3} size="xsmall" margin="small">
                  {name}
                </Heading>
              </Row>
              <Row>
                {months.map((month) => (
                  <Month key={month.month} {...month} />
                ))}
              </Row>
            </Box>
          ))}
        </Box>
      </Swipe>
    </Keyboard>
    </Box>
  );
};

export default Roadmap;
